<template>
  <div class="skypes">
    <div v-if="secure">
      <h1>Конференции по группам</h1>
      <a class="btn btn-big" :href="skypes.url" target="_blank">{{ skypes.name }}</a>
      <router-link class="btn btn-block" :to="{name:'skypes-day', params: { id: i }}" v-for="(block, i) in skypes.blocks" :key="'b-' + i">{{ block.name }}</router-link>
      <router-link class="back"  :to="{name:'home'}">Вернуться</router-link>
    </div>
    <div v-else>
      <label>
        <input type="password" v-model.trim="pwd" placeholder="Секретное слово"  />
      </label>
      <button @click="changeSecure">Отправить</button>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Skypes",
    data: () => {
      return {
        pwd: '',
      }
    },
    created() {
      document.title = "Помощник чтеца";
      this.$store.dispatch('getSkypes');
      this.fetch()
    },
    computed: {
      ...mapState({
        skypes: state => state.skypes,
        secure: state => state.secure,
      })
    },
    methods: {
      fetch() {
        this.checkSecure();
      },
      checkSecure() {
        this.$store.dispatch('testSecure');
      },
      changeSecure() {
        this.$store.dispatch('testSecure', this.pwd);
      },
    },
  }
</script>

<style scoped lang="less">
  @import "../assets/vars";

  .skypes {
    .btn {
      display: block;
      background-color: @color-h;
      color: @color-l;
      text-align: center;
      text-decoration: none;
      font-size: 1.5rem;

      &.btn-big {
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 2rem;
      }

      &.btn-block {
        padding: .5rem;
        margin-bottom: 1rem;
      }
    }

    .back {
      text-align: right;
      color: @color-h;
      display: block;
      text-decoration: none;
    }
  }
</style>
